function DonationThanks() {
  return (
    <div className="flex flex-col py-20 px-40 2xl:px-96 justify-between gap-20">
      <div>
        <p className="text-2xl font-semibold">Thank You for Your Generosity!</p>
        <p>
          Your donation to 'Clean Water For All' has been received and is deeply
          appreciated
        </p>
      </div>
      <div>
        <p className="mb-2">Amount: 100 PYUSD</p>
        <p>Transaction ID: 55b813e5-e720-4ba9-9323-ef79a9fed4fe</p>
      </div>
      <div>
        <p>
          Your support brings us one step closer to providing clean water to
          communities in need.{' '}
        </p>
        <p>You've made a real difference today.</p>
      </div>
    </div>
  );
}
export default DonationThanks;
