import {Link} from 'react-router-dom';
import LogoMin from '../../puplic/svg/logoMin';

function StepOne() {
  return (
    <div className="flex flex-col py-20 px-40 2xl:px-96 justify-between">
      <div className="absolute left-6 top-6">
        <LogoMin className="scale-75" />
      </div>
      <div className="mb-10">
        <p className="mb-4 text-left font-bold text-xl">
          Step 1: Choose Your Storefront Type
        </p>
        <p className="text-left">
          Select the option that best describes your storefront purpose:
        </p>
      </div>
      <div className="flex flex-row mb-10 justify-between items-start">
        <div className="flex flex-col justify-center items-center w-60">
          <div className="flex items-center justify-center w-60 h-60 bg-black mb-4">
            <div className="w-16 h-16 bg-white rounded-md" />
          </div>
          <p className="mb-4 text-left w-full font-medium">
            Fundraise and accept donations for your cause
          </p>
          <p className="text-left w-full text-sm">
            Select fundraising and donations
          </p>
        </div>
        <div className="flex flex-col justify-center items-center w-60">
          <div className="relative flex items-center justify-center w-60 h-60 bg-gray-300 mb-4">
            <p className="absolute top-1 left-1 text-xs">Comming soon</p>
            <div className="w-16 h-16 bg-white rounded-md" />
          </div>
          <p className="mb-4 text-left w-full font-medium">
            Sell tangible goods that you'll ship to customers
          </p>
          <p className="text-left w-full text-sm">Select tangible goods</p>
        </div>
        <div className="flex flex-col justify-center items-center w-60">
          <div className="relative flex items-center justify-center w-60 h-60 bg-gray-300 mb-4">
            <p className="absolute top-1 left-1 text-xs">Comming soon</p>
            <div className="w-16 h-16 bg-white rounded-md" />
          </div>
          <p className="mb-4 text-left w-full font-medium">
            Offer digital items like software, ebooks or courses
          </p>
          <p className="text-left w-full text-sm">Select Digital items</p>
        </div>
      </div>
      <div>
        <Link
          to="/new-causes-step-two"
          className="py-2 px-8 bg-black text-white rounded-md">
          Next Step
        </Link>
      </div>
    </div>
  );
}
export default StepOne;
