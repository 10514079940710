import DashboardNavigation from '../../components/dashboardNavigation';
import CausesList from '../../components/causesList';
import {Link} from 'react-router-dom';
import {useEffect, useState} from 'react';
import {fetchCausesList} from '../../api/causes';

function Causes({setIsAuthenticated}) {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const getData = async () => {
      setLoading(true);
      const fetchedData = await fetchCausesList();
      if (fetchedData) {
        setData(fetchedData);
      } else {
        setData([]);
      }
      setLoading(false);
    };

    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      <DashboardNavigation setIsAuthenticated={setIsAuthenticated} />
      <div className="flex flex-col py-14 px-60 2xl:px-96 justify-between">
        <div className="flex flex-row justify-between mb-20">
          <p className="text-lg">All causes</p>
          <div>
            {/* <p className="text-lg">Total received amount: 50 PYUSD </p> */}
            <div>
              <Link
                to="/new-causes-step-one"
                className="py-2 px-8 bg-black text-white rounded-md">
                New Causes
              </Link>
            </div>
          </div>
        </div>
        <div>
          {loading ? (
            // Icono animado de espera
            <div className="flex justify-center w-full">
              <div className="animate-spin rounded-full h-20 w-20 border-t-2 border-gray-400"></div>
            </div>
          ) : (
            <div>
              <CausesList data={data} />
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
export default Causes;
