import {useNavigate} from 'react-router-dom';
import React, {useState} from 'react';
import {signUp} from '../../api/auth';

const Signup = ({setIsAuthenticated}) => {
  const navigate = useNavigate();
  const [username, setUsername] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [message, setMessage] = useState('');

  const validateEmail = email => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const handleSubmit = async e => {
    e.preventDefault();
    setMessage('');

    if (username !== '' && email !== '' && password !== '') {
      if (validateEmail(email)) {
        await signUp({
          name: username,
          email: email,
          password: password,
        })
          .then(() => {
            setIsAuthenticated(true);
            navigate('/new-causes-step-one');
          })
          .catch(error => {
            setMessage(error.message ? error.message : '');
          });
      } else {
        setMessage('Email is incorrect.');
      }
    } else {
      setMessage('User, email or password is missing.');
    }
  };

  return (
    <div className="flex h-screen w-full flex-col justify-center items-center gap-10 px-[400px] 2xl:px-96">
      <form
        className="flex flex-col items-center w-1/2"
        onSubmit={handleSubmit}>
        <div className="mb-4">
          <p className="text-left w-full">User name</p>
          <input
            type="text"
            value={username}
            onChange={e => setUsername(e.target.value)}
            className="border border-black hover:bg-gray-100 rounded-md px-4 py-2 w-full"></input>
        </div>
        <div className="mb-4">
          <p className="text-left w-full">Email</p>
          <input
            type="text"
            value={email}
            onChange={e => setEmail(e.target.value)}
            className="border border-black hover:bg-gray-100 rounded-md px-4 py-2 w-full"></input>
        </div>
        <div className="mb-4">
          <p className="text-left w-full">Password</p>
          <input
            type="password"
            value={password}
            onChange={e => setPassword(e.target.value)}
            className="border border-black hover:bg-gray-100 rounded-md px-4 py-2 w-full"></input>
        </div>
        {message === '' ? (
          <div className="mb-4 opacity-0">
            <p>.</p>
          </div>
        ) : (
          <div className="mb-4">
            <p className="text-red-600">{message}</p>
          </div>
        )}
        <button
          type="submit"
          className="py-2 px-8 w-3/4 2xl:w-52 bg-black text-white rounded-md">
          Sign up
        </button>
      </form>
    </div>
  );
};

export default Signup;
