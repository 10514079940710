function NewDonation() {
  return (
    <div className="flex flex-col py-20 px-40 2xl:px-96 justify-between">
      <p className="mb-2 w-full text-left text-3xl px-4">Clear Water For All</p>
      <div className="flex flex-row">
        <div className="flex flex-col w-3/5 px-5 mb-6">
          <div className="flex items-center justify-center w-full h-96 bg-black mb-4 rounded-md">
            <div className="w-16 h-16 bg-white rounded-md" />
          </div>
          <p className="mb-4 w-full text-left">
            Lorem ipsum, dolor sit amet consectetur adipisicing elit. Alias iste
            porro sapiente? Soluta eligendi laudantium obcaecati tempora eum
            nesciunt error rem sunt, labore possimus consectetur deserunt
            similique fuga nam autem!
          </p>
          <button className="mb-6 w-full text-left underline">Read more</button>
          <div className="flex flex-row">
            <div class="flex items-center pr-4">
              <input
                id="checkbox"
                type="checkbox"
                value=""
                class="w-8 h-8 bg-gray-300 rounded dark:bg-gray-600"
              />
            </div>
            <div className="flex flex-col">
              <p className="w-full text-left text-lg font-semibold">
                Verified donation
              </p>
              <p className="w-full text-left">
                Your transaction is secure and your contribution is guaranteed
                to reach the intended cause.
              </p>
            </div>
          </div>
        </div>
        <div className="flex flex-col w-2/5 px-5">
          <div className="flex flex-row justify-between mb-4">
            <p className="font-semibold text-xl">98,960 PYUSD</p>
            <p>raised of 100,000 PYUSD goal</p>
          </div>
          <div className="w-full h-8 mb-2 rounded-full bg-gray-300">
            <div className="w-[70%] h-8 bg-black rounded-full"></div>
          </div>
          <p className="w-full text-left mb-10 font-medium">2.K donations</p>
          <div className="flex flex-col gap-4 w-full">
            <div className="flex flex-row gap-4">
              <button className="py-2 px-8 bg-black text-white rounded-md w-1/2">
                <p>10 PYUSD</p>
              </button>
              <button className="py-2 px-8 bg-black text-white rounded-md w-1/2">
                <p>20 PYUSD</p>
              </button>
            </div>
            <div className="flex flex-row gap-4">
              <button className="py-2 px-8 bg-black text-white rounded-md w-1/2">
                <p>50 PYUSD</p>
              </button>
              <button className="py-2 px-8 bg-black text-white rounded-md w-1/2">
                <p>100 PYUSD</p>
              </button>
            </div>
            <label className="flex flex-row border-2 border-black rounded-md">
              <p className="flex justify-center items-center ml-4 text-gray-500 text-lg">
                PYUSD
              </p>
              <input
                type="text"
                placeholder=".00"
                className="px-4 py-2 w-full outline-none text-right text-3xl text-gray-500 "></input>
              <button className="py-4 px-8 m-2 bg-black text-white rounded-md w-1/2">
                <p>Donate</p>
              </button>
            </label>
          </div>
        </div>
      </div>
      <div>
        <button className="py-2 px-8 bg-black text-white rounded-md">
          Submit
        </button>
      </div>
    </div>
  );
}
export default NewDonation;
