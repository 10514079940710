import DashboardNavigation from '../../components/dashboardNavigation';
import CausesDetails from '../../components/causesDetails';
import {useState, useEffect} from 'react';
import {
  getLastTransaction,
  //fetchCauseLastsTransactions,
} from '../../api/donations';
//import DonationsDetails from '../../components/donationsDetails';
//import {fetchCausesIds} from '../../api/causes';

function Donations({setIsAuthenticated}) {
  //const [session, setSession] = useState({});
  const [transactions, setDataTransactions] = useState([]);
  //const [donations, setDonations] = useState([]);
  //const [causeList, setCauseList] = useState([]);
  //const [selectedCause, setSelectedCause] = useState();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const getData = async () => {
      setLoading(true);

      const transactions = await getLastTransaction();

      /*       const causes = await fetchCausesIds();
      if (causes) {
        setCauseList(causes);
      } */

      if (transactions) {
        setDataTransactions(transactions);
      } else {
        setDataTransactions([]);
      }
      setLoading(false);
    };

    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /*   const handleSelectChange = async event => {
    const value = event.target.value;
    setSelectedCause(value);
    const donations = await fetchCauseLastsTransactions({
      userToken: JSON.parse(session).token,
      causeId: value,
    });
    if (donations) {
      setDonations(donations);
    }
  }; */

  return (
    <div>
      <DashboardNavigation setIsAuthenticated={setIsAuthenticated} />
      <div className="flex flex-col py-14 px-60 w-full 2xl:px-96 justify-between">
        <p className="w-full text-left text-lg font-semibold mb-6">
          Wallet transactions
        </p>
        {loading ? (
          // Icono animado de espera
          <div className="flex justify-center w-full">
            <div className="animate-spin rounded-full h-20 w-20 border-t-2 border-gray-400"></div>
          </div>
        ) : (
          <div>
            <CausesDetails data={transactions} />
          </div>
        )}
      </div>
    </div>
  );
}
export default Donations;
