import * as React from 'react';
import {Link} from 'react-router-dom';
import FrameDesign1 from '../puplic/svg/frameDesign1';
import FrameDesign2 from '../puplic/svg/frameDesign2';
import BlackLogoNoBackground from '../puplic/svg/blackLogoNoBackground';
//import TwitterX from '../puplic/svg/TwitterX';

const Index = () => {
  return (
    <div className="flex h-screen w-full flex-col justify-between items-center">
      <div className="flex flex-col justify-between items-center my-16 gap-10">
        <BlackLogoNoBackground width={126} height={104} />
        <div className="text-2xl font-bold">
          Sell online in minutes. No bank account needed.
        </div>
        <div className="text-lg font-normal max-w-lg">
          Create your digital store and receive payments instantly. Share
          products anywhere and start selling with just a few clicks.
        </div>
        <Link
          to="/signin"
          className="flex items-center flex-row bg-black text-white py-4 px-8 rounded-lg">
          {/*  <TwitterX width={20} height={20} /> */}
          <div className="font-semibold">Sign in</div>
          {/* ml-4 */}
        </Link>
        <Link
          to="/signup"
          className="flex items-center flex-row bg-black text-white py-4 px-8 rounded-lg">
          {/*  <TwitterX width={20} height={20} /> */}
          <div className="font-semibold">Sign up</div>
        </Link>
        <div className="text-sm text-gray-500">
          By signing up, you agree to our{' '}
          <dev className="underline">(Terms of Service)</dev> and{' '}
          <dev className="underline">(Data Processing Agreement)</dev>
        </div>
      </div>
      <div className="flex w-full flex-row justify-between items-end">
        <div className="scale-75 translate-y-7 -translate-x-14">
          <FrameDesign1 width={422} height={227} />
        </div>
        <div className="scale-75 translate-y-7 translate-x-12">
          <FrameDesign2 width={423} height={227} />
        </div>
      </div>
    </div>
  );
};

export default Index;
