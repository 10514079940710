import * as React from "react";
const FrameDesign2 = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={423}
    height={227}
    fill="none"
    {...props}
  >
    <path
      fill="#000"
      stroke="#000"
      strokeWidth={1.572}
      d="m340.298 226.386 110.116 110.116V226.386H340.298Z"
    />
    <path
      stroke="#000"
      strokeWidth={1.572}
      d="m227.498 113.586 110.116 110.116V113.586H227.498Zm-2.684 1.112L114.698 224.814h110.116V114.698Z"
    />
    <circle
      cx={56.4}
      cy={56.4}
      r={55.614}
      stroke="#000"
      strokeWidth={1.572}
      transform="matrix(-1 0 0 1 451.2 1.41)"
    />
    <circle
      cx={14.1}
      cy={14.1}
      r={13.314}
      fill="#000"
      stroke="#000"
      strokeWidth={1.572}
      transform="matrix(-1 0 0 1 28.2 86.01)"
    />
    <path
      fill="#000"
      stroke="#000"
      strokeWidth={1.572}
      d="M224.814.786H113.586v111.228h111.228z"
    />
  </svg>
);
export default FrameDesign2;
