import {useNavigate} from 'react-router-dom';
import React, {useState} from 'react';
import {signIn} from '../../api/auth';

const Signin = ({setIsAuthenticated}) => {
  const navigate = useNavigate();
  const [email, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [message, setMessage] = useState('');

  const handleSubmit = async e => {
    e.preventDefault();
    setMessage('');

    const response = await signIn({email: email, password: password});
    console.log(response);

    if (response.status === 200) {
      setIsAuthenticated(true);
      navigate('/causes');
    } else {
      setMessage(response.message);
    }
  };

  return (
    <div className="flex h-screen w-full flex-col justify-center items-center gap-10 px-[400px] 2xl:px-96">
      <form onSubmit={handleSubmit}>
        <div className="mb-4 ">
          <p className="text-left w-full">Email</p>
          <input
            type="text"
            value={email}
            onChange={e => setUsername(e.target.value)}
            className="border border-black hover:bg-gray-100 rounded-md px-4 py-2 w-full"></input>
        </div>
        <div className="mb-4">
          <p className="text-left w-full">Password</p>
          <input
            type="password"
            value={password}
            onChange={e => setPassword(e.target.value)}
            className="border border-black hover:bg-gray-100 rounded-md px-4 py-2 w-full"></input>
        </div>
        {message === '' ? (
          <div className="mb-4 opacity-0">
            <p>.</p>
          </div>
        ) : (
          <div className="mb-4">
            <p className="text-red-600">{message}</p>
          </div>
        )}
        <button
          type="submit"
          className="py-2 px-8 w-3/4 bg-black text-white rounded-md">
          Login
        </button>
      </form>
    </div>
  );
};

export default Signin;
