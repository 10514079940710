import Portal from '@portal-hq/web';

export const fetchAccountBalance = async ({userToken}) => {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_PAYUSD_API}/api/users/solana-asstes`,
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${userToken}`,
        },
      },
    );

    if (response.ok) {
      const result = await response.json();

      const formattedData = result.assets.tokenBalances.map(item => ({
        symbol: item.symbol || '',
        balance: item.balance || '',
      }));

      return {
        nativeBalance: {
          symbol: result.assets.nativeBalance.symbol || '',
          balance: result.assets.nativeBalance.balance || '',
        },
        tokenBalances: formattedData,
      };
    } else {
      const errorData = await response.json();
      throw new Error(errorData.message || 'account balance Error');
    }
  } catch (error) {
    if (error instanceof Error) {
      console.log(`Error during account balance: ${error.message}`);
      return {
        nativeBalance: {
          symbol: '',
          balance: '',
        },
        tokenBalances: [
          {
            symbol: '',
            balance: '',
          },
        ],
      };
    } else {
      throw new Error('Unknown error during account balance');
    }
  }
};

export const sendFunds = async data => {
  try {
    const bearerToken = JSON.parse(sessionStorage.getItem('user-session'));
    const response = await fetch(
      `${process.env.REACT_APP_PAYUSD_API}/api/users/send-assets`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${bearerToken.token}`,
        },
        body: JSON.stringify(data),
      },
    );
    if (response.ok) {
      const result = await response.json();

      return result;
    } else {
      if (response.status === 400) {
        throw new Error('missing data');
      } else {
        const errorData = await response.json();
        throw new Error(errorData.message || 'send Founds Error');
      }
    }
  } catch (error) {
    if (error instanceof Error) {
      throw new Error(`Error during send Founds: ${error.message}`);
    } else {
      throw new Error('Unknown error during send Founds');
    }
  }
};

export const sendTokensOnSolana = async (to, tokenMint, tokenAmount) => {
  const session = sessionStorage.getItem('user-session');

  const portal = new Portal({
    apiKey: session.clientApiKey,
    autoApprove: true,
    rpcConfig: {
      [process.env.REACT_APP_SOLANA_CHAIN_ID]:
        process.env.REACT_APP_SOLANA_RPC_URL,
    },
  });

  if (!portal || !portal?.ready) throw new Error('Portal has not initialised');

  const res = await fetch('/api/buildSolanaTransaction', {
    method: 'POST',
    body: JSON.stringify({
      to,
      token: tokenMint,
      amount: String(tokenAmount),
    }),
  });

  const response = await res.json();

  if (response.error) throw new Error(response.error);

  const txnHash = await portal.request({
    chainId: process.env.solanaChainId,
    method: 'sol_signAndSendTransaction',
    params: response.transaction,
  });

  return txnHash;
};
