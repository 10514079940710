import {Link} from 'react-router-dom';
import {createLink} from './linkGenerator';
import {formatAmount} from '../components/formater';
import TwitterX from '../puplic/svg/TwitterX';

function CausesList({data}) {
  const lastItem = data.length - 1;

  const goalPercentage = (funds, goal) => {
    const percentage = (funds / goal) * 100;
    if (percentage > 5) {
      return (funds / goal) * 100;
    } else if (percentage > 0) {
      return 5;
    } else {
      return 0;
    }
  };

  return (
    <div>
      {data.length > 0 ? (
        data.map((item, index) => (
          <div
            className={`flex flex-col mb-5 justify-between ${lastItem === index ? '' : 'border-b border-black'} pb-2`}
            key={index}>
            <div className="flex flex-row justify-between mb-4">
              <div className="flex flex-col gap-4">
                <div className="flex flex-row items-start gap-4">
                  <Link
                    to={item.shareLink}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="flex flex-row items-center gap-2 py-px px-2 bg-black text-white rounded-md">
                    <TwitterX className="scale-50" />
                    Share
                  </Link>
                  <Link
                    to={createLink(item.link)}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="font-semibold underline truncate max-w-80">
                    {item.name}
                  </Link>
                  {/* <p className="text-xl">{item.name}</p> */}
                </div>
                <div className="flex flex-row gap-2">
                  <p className="text-sx text-left max-w-96 line-clamp-3">
                    {item.description}
                  </p>
                </div>
              </div>
              {/* <p className="text-lg">{item.status}</p> */}
              <div className="flex flex-col justify-between">
                <div className="flex flex-col w-1/2 items-start">
                  <div className="flex flex-row gap-4 justify-between">
                    <p className="text-lg">Funds:</p>
                    <p className="flex font-semibold items-center max-w-60 truncate">
                      {formatAmount(item.funds)} PYUSD
                    </p>
                  </div>
                  <div className="flex flex-row gap-4 justify-between">
                    <p className="text-lg">Goal: </p>
                    <p className="flex font-semibold items-center max-w-60 truncate">
                      {formatAmount(parseInt(item.amount))} PYUSD
                    </p>
                  </div>
                </div>
                <div className="flex h-5 w-80 justify-end">
                  <div className="w-full bg-gray-300 rounded-full h-5">
                    <div
                      className="bg-black h-5 rounded-full"
                      style={{
                        width: `${goalPercentage(item.funds, parseInt(item.amount))}%`,
                      }}></div>
                  </div>
                </div>
              </div>
            </div>
            <div className="flex flex-row justify-end gap-2">{/*  */}</div>
          </div>
        ))
      ) : (
        <div className="flex flex-col mb-5 justify-between border-b border-black pb-5">
          {' '}
          <div className="flex flex-row justify-between mb-4">
            <p className="text-xl">There is still no cause...</p>
          </div>
        </div>
      )}
    </div>
  );
}
export default CausesList;
