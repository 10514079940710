import {Link} from 'react-router-dom';
//import {formatDate} from '../components/formater';

function CausesDetails({data}) {
  const lastItem = data.length - 1;

  return (
    <div>
      {data.length > 0 ? (
        data.map((item, id) => (
          <Link
            key={id}
            to={`https://explorer.solana.com/tx/${item.signature}?cluster=devnet`}
            target="_blank"
            rel="noopener noreferrer"
            className={`flex items-center py-5 px-10 rounded mb-2 hover:bg-gray-100 ${lastItem === id ? '' : 'border-b border-gray-400'}`}>
            <div className="flex flex-col justify-between">
              <div className="flex flex-row justify-start items-center gap-2">
                <p className="max-w-96 truncate text-lg font-semibold">
                  {item.causeName !== '' ? item.causeName : 'Unknown Cause'}
                </p>
              </div>
              <div className="flex flex-row justify-start items-center gap-2">
                <p className="max-w-96 truncate font-semibold">{item.amount}</p>
                <p className="text-lg">PYUSD</p>
              </div>
              <div className="flex flex-row justify-start items-center gap-2">
                <p className="text-lg">Status: </p>
                <p className="text-lg font-semibold">
                  {item.confirmationStatus}
                </p>
              </div>
              <div className="flex flex-row justify-start items-center gap-2">
                <p className="text-lg">Transaction: </p>
                <p className="max-w-96 truncate font-semibold underline">
                  {item.signature}
                </p>
              </div>
              <div className="flex flex-row justify-start items-center gap-2">
                <p className="max-w-96 truncate font-semibold">{item.date}</p>
              </div>
            </div>
          </Link>
        ))
      ) : (
        <div className="flex flex-col mb-5 justify-between border-b border-black py-5 mx-10">
          {' '}
          <div className="flex flex-row justify-between mb-4">
            <p className="text-xl">There is still no transactions...</p>
          </div>
        </div>
      )}
    </div>
  );
}
export default CausesDetails;
