import TwitterX from '../../puplic/svg/TwitterX';
import React, {useState} from 'react';
import {Link, useLocation} from 'react-router-dom';
import {createLink} from '../../components/linkGenerator';
import LogoMin from '../../puplic/svg/logoMin';

function StepThree() {
  const location = useLocation();
  const data = location.state.fundraising || {};

  const link = `${process.env.REACT_APP_PAYUSD_API}/${data.slug}`;

  const twitterXLink = `https://twitter.com/intent/tweet?text=${encodeURIComponent(createLink(data.slug))}`;

  const [textToCopy] = useState(link);

  const copyText = () => {
    navigator.clipboard
      .writeText(textToCopy)
      .then(() => {})
      .catch(err => {
        console.error('Error copying: ', err);
      });
  };

  return (
    <div className="flex flex-col py-20 px-40 2xl:px-96 justify-between">
      <div className="absolute left-6 top-6">
        <LogoMin className="scale-75" />
      </div>
      <div className="mb-20">
        <p className="mb-4 text-center font-bold text-xl">
          Congratulations! Your Fundraising Journey Begins
        </p>
        <p className="text-center">
          Your cause is live and your digital account is ready.
        </p>
      </div>
      <div className="mb-20 flex flex-col items-center">
        <p className="text-center mb-4">Cause created: {data.name}</p>
        <div className="flex gap-2 items-center justify-center mb-4">
          <p className="flex text-center gap-1">
            Your Storefont Page is:
            <p className="underline">{textToCopy}</p>
          </p>
          <button
            onClick={copyText}
            className="text-gray-500 active:text-gray-800 text-sm rounded p-2">
            Copy
          </button>
        </div>
        <p className="text-center mb-4">
          Start spreading the word and making an impact!
        </p>
        <Link
          to={twitterXLink}
          target="_blank"
          rel="noopener noreferrer"
          className="flex flex-row gap-4 py-2 px-8 w-64 bg-black text-white rounded-md">
          <TwitterX width={20} height={20} />
          Tweet your cause now
        </Link>
      </div>
      <div className="flex justify-center items-end w-full 2xl:h-96">
        <Link to="/causes" className="py-2 px-8 bg-black text-white rounded-md">
          Go to my Storefront
        </Link>
      </div>
    </div>
  );
}
export default StepThree;
