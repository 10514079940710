import {useEffect, useState} from 'react';
import DashboardNavigation from '../../components/dashboardNavigation';
import {fetchAccountBalance} from '../../api/account';
import TransferModal from '../../components/transferModal';
/* import TwitterX from '../../puplic/svg/TwitterX';
import {formatAmount} from '../../components/formater';
*/

/* const activities = [
  {
    id: 1,
    type: 'Funds sending',
    itWas: 'Two days ago',
    from: '2q7pyh.....gQfd',
    amount: 50000,
  },
  {
    id: 2,
    type: 'Funds sending',
    itWas: 'Two days ago',
    from: '2q7pyh.....gQfd',
    amount: 50000,
  },
  {
    id: 3,
    type: 'Funds sending',
    itWas: 'Two days ago',
    from: '2q7pyh.....gQfd',
    amount: 50000,
  },
  {
    id: 4,
    type: 'Funds sending',
    itWas: 'Two days ago',
    from: '2q7pyh.....gQfd',
    amount: 50000,
  },
]; */

function Account({setIsAuthenticated}) {
  //const lastActivityItem = activities.length - 1;
  const [userData, setUserData] = useState({});
  const [balances, setBalances] = useState({
    nativeBalance: {
      symbol: '',
      balance: '',
    },
    tokenBalances: [],
  });
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const getData = async () => {
      setLoading(true);
      const session = sessionStorage.getItem('user-session');
      const sessionObj = JSON.parse(session);

      setUserData(sessionObj);
      const balanceData = await fetchAccountBalance({
        userToken: sessionObj.token,
      });
      if (balanceData) {
        setBalances(balanceData);
      } else {
        setBalances({
          nativeBalance: {
            symbol: '',
            balance: '',
          },
          tokenBalances: [],
        });
      }
      setLoading(false);
    };
    getData();
  }, []);

  return (
    <div>
      <DashboardNavigation setIsAuthenticated={setIsAuthenticated} />
      <div className="flex flex-col py-14 px-60 2xl:px-96 justify-between">
        <p className="w-full text-left text-lg">Profile</p>
        <div className="px-40">
          <div className="flex flex-col mb-10 pb-5 border-b border-black">
            <p className="w-full text-left mb-2">Full name</p>
            <input
              type="text"
              disabled
              value={userData.name}
              className="border border-black hover:bg-gray-100 rounded-md px-4 py-2 mb-4 w-3/4"></input>
            <p className="w-full text-left mb-2">Email</p>
            <input
              type="text"
              disabled
              value={userData.email}
              className="border border-black hover:bg-gray-100 rounded-md px-4 py-2 mb-4 w-3/4"></input>
            {/*             <div className="flex flex-row items-center gap-6 mt-5">
              <button className="flex flex-row gap-4 py-2 px-8 w-64 bg-black text-white rounded-md">
                <TwitterX width={20} height={20} />
                Continue with Twitter
              </button>
              <button className="text-gray-700 active:text-gray-900">
                Unlink account
              </button>
            </div> */}
          </div>
        </div>
        <p className="w-full text-left text-lg">Balance</p>
        <div className="px-40">
          <div className="flex flex-col items-end gap-6 mb-10 border-b pb-5 border-black">
            {loading ? (
              // Icono animado de espera
              <div className="flex justify-center w-full">
                <div className="animate-spin rounded-full h-20 w-20 border-t-2 border-gray-400"></div>
              </div>
            ) : (
              <div className="flex flex-col items-end gap-6">
                <p className="text-lg font-semibold">
                  {balances.nativeBalance.balance}{' '}
                  {balances.nativeBalance.symbol}
                </p>
                {balances.tokenBalances.map((item, index) => (
                  <p key={index} className="text-lg font-semibold">
                    {item.balance} {item.symbol}
                  </p>
                ))}
              </div>
            )}
            <TransferModal
              isHaveFunds={
                balances.nativeBalance.symbol !== '' &&
                balances.tokenBalances.length > 0
              }
            />
          </div>
        </div>
      </div>
    </div>
  );
}
export default Account;
