import {Link, useLocation} from 'react-router-dom';
import LogoMin from '../puplic/svg/logoMin';

function DashboardNavigation({setIsAuthenticated}) {
  const location = useLocation();

  const handleLogOut = () => {
    sessionStorage.removeItem('user-session');
    setIsAuthenticated(false);
  };

  return (
    <div className="flex flex-row gap-40 justify-center">
      <div className="absolute left-6 top-6">
        <LogoMin className="scale-75" />
      </div>
      <div className="flex flex-row gap-40 pt-10 justify-center">
        <Link
          to="/causes"
          className={`${location.pathname === '/causes' ? 'font-semibold' : ''}`}>
          Campaigns
        </Link>
        <Link
          to="/donations"
          className={`${location.pathname === '/donations' ? 'font-semibold' : ''}`}>
          Transactions {/* Donations */}
        </Link>
        <Link
          to="/account"
          className={`${location.pathname === '/account' ? 'font-semibold' : ''}`}>
          Account
        </Link>
      </div>
      <button
        onClick={handleLogOut}
        className="pt-10 underline absolute right-6">
        Logout
      </button>
    </div>
  );
}
export default DashboardNavigation;
