import Portal from '@portal-hq/web';

export const signIn = async data => {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_PAYUSD_API}/api/users/login`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      },
    );
    if (response.ok) {
      const result = await response.json();
      sessionStorage.setItem('user-session', JSON.stringify(result.data));
      return {status: 200};
    } else {
      if (response.status === 400) {
        return {status: 400, message: 'Email or password is missing.'};
      } else if (response.status === 401) {
        return {status: 401, message: 'Incorrect email or password.'};
      } else {
        const errorData = await response.json();
        return {status: null, message: errorData.message || 'LogIn Error'};
      }
    }
  } catch (error) {
    if (error instanceof Error) {
      return {
        status: 500,
        message: `Error during login: ${error.message}` || 'LogIn server Error',
      };
    } else {
      return {status: 500, message: 'LogIn server Error'};
    }
  }
};

export const signUp = async data => {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_PAYUSD_API}/api/users/register`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      },
    );
    if (response.ok) {
      const result = await response.json();
      const userApiKey = result.data.clientApiKey;

      const portal = new Portal({
        apiKey: userApiKey,
        autoApprove: true,
        rpcConfig: {
          [process.env.REACT_APP_SOLANA_CHAIN_ID]:
            process.env.REACT_APP_SOLANA_RPC_URL,
        },
      });

      portal.onReady(async () => {
        const walletExists = await portal.doesWalletExist();
        if (!walletExists) {
          await portal.createWallet();
        }

        const solAddress = await portal.getSolanaAddress();
        const ethAddress = await portal.getEip155Address();

        const walletAddress = {
          solAddress: solAddress,
          ethAddress: ethAddress,
        };

        const userData = {
          name: result.data.name,
          portal_client_id: result.data.clientId,
          portal_client_api_key: result.data.clientApiKey,
          solana_address: walletAddress.solAddress,
          ethereum_address: walletAddress.ethAddress,
        };

        const putUserResponse = await fetch(
          `${process.env.REACT_APP_PAYUSD_API}/api/users/user`,
          {
            method: 'PUT',
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${result.data.token}`,
            },
            body: JSON.stringify(userData),
          },
        );

        if (putUserResponse.ok) {
          const putUserResult = await putUserResponse.json();
          await sessionStorage.setItem(
            'user-session',
            JSON.stringify(putUserResult.data),
          );
          return {status: 201};
        } else {
          const errorData = await putUserResponse.json();
          return {
            status: errorData.status,
            message: errorData.message || 'registration Error',
          };
        }
      });
    } else {
      if (response.status === 400) {
        return {
          status: response.status,
          message: 'Email or password is missing.',
        };
      } else if (response.status === 409) {
        if (response.message === 'El usuario ya existe') {
          return {
            status: response.status,
            message: 'User already exists.',
          };
        } else {
          return {
            status: response.status,
            message: 'Error creating user in Portal.',
          };
        }
      } else {
        return {
          status: response.status,
          message: response.message || response.statusText,
        };
      }
    }
  } catch (error) {
    if (error instanceof Error) {
      return {message: `Error during registration: ${error.message}`};
    } else {
      return {message: 'Unknown error during registration.'};
    }
  }
};

export const portalTest = async () => {};
