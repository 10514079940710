import * as React from 'react';
const Camera = props => (
  <svg
    width={44}
    height={39}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <path
      d="M21.75 30.766c2.525 0 4.659-.871 6.402-2.614 1.743-1.743 2.614-3.877 2.614-6.402 0-2.525-.871-4.659-2.614-6.402-1.743-1.743-3.877-2.614-6.402-2.614-2.525 0-4.659.871-6.402 2.614-1.743 1.743-2.614 3.877-2.614 6.402 0 2.525.871 4.659 2.614 6.402 1.743 1.743 3.877 2.614 6.402 2.614Zm0-2.417c-1.878 0-3.447-.63-4.708-1.891-1.26-1.261-1.891-2.83-1.891-4.708 0-1.878.63-3.447 1.891-4.708 1.261-1.26 2.83-1.891 4.708-1.891 1.878 0 3.447.63 4.708 1.891 1.26 1.261 1.891 2.83 1.891 4.708 0 1.878-.63 3.447-1.891 4.708-1.261 1.26-2.83 1.891-4.708 1.891ZM3.904 38.667c-1.112 0-2.041-.373-2.786-1.118C.373 36.804 0 35.875 0 34.763V8.737c0-1.112.373-2.04 1.118-2.786.745-.745 1.674-1.118 2.786-1.118h7.147L15.522 0h12.456l4.47 4.833h7.148c1.112 0 2.041.373 2.786 1.118.745.745 1.118 1.674 1.118 2.786v26.026c0 1.112-.373 2.04-1.118 2.786-.745.745-1.674 1.118-2.786 1.118H3.904Zm0-2.417h35.692c.434 0 .79-.14 1.07-.418.278-.28.417-.636.417-1.07V8.738c0-.433-.139-.79-.418-1.069-.279-.279-.635-.418-1.07-.418H31.39l-4.457-4.833H16.568L12.111 7.25H3.904c-.434 0-.79.14-1.07.418-.278.28-.417.636-.417 1.07v26.025c0 .433.139.79.418 1.069.279.279.635.418 1.07.418Z"
      fill="#5F6368"
    />
  </svg>
);
export default Camera;
