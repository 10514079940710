import * as React from "react";
const FrameDesign1 = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={422}
    height={227}
    fill="none"
    {...props}
  >
    <path
      fill="#000"
      stroke="#000"
      strokeWidth={1.572}
      d="M81.702 226.386-28.414 336.502V226.386H81.702Z"
    />
    <path
      stroke="#000"
      strokeWidth={1.572}
      d="M194.502 113.586 84.386 223.702V113.586h110.116Zm2.684 1.112 110.116 110.116H197.186V114.698Z"
    />
    <circle cx={27.2} cy={57.81} r={55.614} stroke="#000" strokeWidth={1.572} />
    <circle
      cx={407.9}
      cy={100.11}
      r={13.314}
      fill="#000"
      stroke="#000"
      strokeWidth={1.572}
    />
    <path
      fill="#000"
      stroke="#000"
      strokeWidth={1.572}
      d="M197.186.786h111.228v111.228H197.186z"
    />
  </svg>
);
export default FrameDesign1;
