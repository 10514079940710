import * as React from 'react';
const LogoMin = props => (
  <svg
    width={37}
    height={71}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <path
      d="M35.34 42.352c2.047-1.938 1.723-4.673-.72-6.077l-4.605-2.65c-2.442-1.405-3.91-4.8-3.262-7.541l5.545-23.508c.647-2.742-.498-3.401-2.543-1.463l-28.41 26.9c-2.045 1.937-1.72 4.672.722 6.077l4.604 2.65c2.441 1.405 3.91 4.8 3.264 7.54L4.387 67.788c-.646 2.742.499 3.4 2.545 1.463L35.34 42.352Z"
      fill="#000"
    />
  </svg>
);
export default LogoMin;
