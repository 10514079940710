import * as React from 'react';
const BlackLogoNoBackground = props => (
  <svg
    width={126}
    height={104}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <path
      d="M80.179 42.352c2.047-1.938 1.722-4.673-.72-6.077l-4.605-2.65c-2.442-1.405-3.91-4.8-3.262-7.541l5.545-23.508c.647-2.742-.498-3.401-2.543-1.463l-28.41 26.9c-2.046 1.938-1.721 4.672.72 6.077l4.605 2.65c2.441 1.405 3.91 4.8 3.264 7.54l-5.547 23.508c-.646 2.742.498 3.4 2.545 1.463l28.408-26.899ZM8.414 103.071c-4.377 0-7.793-2.103-8.414-5.929l4.376-1.002c.282 2.287 1.962 3.472 4.207 3.472 1.722 0 3.092-.762 3.078-2.484-.015-1.92-2.273-2.527-4.758-3.29-2.993-.93-6.197-2.031-6.197-5.786 0-3.798 3.106-5.944 7.2-5.944 3.585 0 7.2 1.455 8.018 5.365l-4.094 1.016c-.381-2.033-1.75-2.922-3.67-2.922-1.708 0-3.163.706-3.163 2.386 0 1.567 2.005 2.09 4.32 2.78 3.078.933 6.72 2.118 6.72 6.184 0 4.334-3.628 6.155-7.623 6.155Zm20.568 0c-6 0-10.433-4.094-10.433-10.489 0-6.409 4.433-10.474 10.433-10.474 5.985 0 10.418 4.065 10.418 10.474 0 6.395-4.433 10.489-10.418 10.489Zm0-3.882c3.515 0 6.211-2.47 6.211-6.606 0-4.123-2.696-6.579-6.211-6.579s-6.212 2.456-6.212 6.578c0 4.137 2.697 6.607 6.212 6.607Zm18.55-.141h6.394v3.741h-10.63V82.391h4.235v16.657Zm25.77-16.658v3.727H68.46v16.672h-4.193V86.117h-4.87V82.39h13.905Zm3.204 20.399V82.39h4.235v20.399h-4.235ZM98.981 82.39h4.503l-7.538 20.399h-4.66L83.792 82.39h4.49l5.35 15.458 5.35-15.458Zm16.601 20.681c-6 0-10.433-4.094-10.433-10.489 0-6.409 4.433-10.474 10.433-10.474 5.985 0 10.418 4.065 10.418 10.474 0 6.395-4.433 10.489-10.418 10.489Zm0-3.882c3.515 0 6.211-2.47 6.211-6.607 0-4.122-2.696-6.578-6.211-6.578s-6.212 2.456-6.212 6.578c0 4.137 2.697 6.607 6.212 6.607Z"
      fill="#000"
    />
  </svg>
);
export default BlackLogoNoBackground;
