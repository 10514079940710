import * as React from "react"
const TwitterX = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={20}
    height={20}
    fill="none"
    {...props}
  >
    <path
      fill="#fff"
      d="M11.905 8.47 19.351 0h-1.764l-6.469 7.352L5.956 0H0l7.809 11.12L0 20h1.764l6.827-7.766L14.044 20H20L11.905 8.47Zm-2.417 2.747-.793-1.108L2.401 1.3h2.71l5.082 7.111.789 1.108 6.604 9.242h-2.71l-5.388-7.545Z"
    />
  </svg>
)
export default TwitterX
